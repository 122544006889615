@import '../helpers/helpers';

.mobile-hamburger-menu {
  order: 1;
  display: flex;
  align-items: center;
}

.mobile-burgermenu-popup {
  transition: all 0.3s ease-in-out;
  position: fixed;
  z-index: 999;
  width: 100%;
  max-width: 500px;
  opacity: 0;
  right: auto;
  top: 90px;
  background: #ffffff;
  padding: 15px;
  box-shadow: 0 3px 6px #00000029;
  min-width: 320px;
  visibility: hidden;
  max-height: calc(100% - 90px);
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width:10px;
    background-color: $gray100;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray400;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: $brand;
    transition: all .5s linear .1s;
  }

  .nav-menu {
    flex-direction: column;

    &_item a {
      padding: 7px;
      color: $gray400;
      display: block;
    }

    &_item a.active,
    &_item a:hover {
      color: $brand;
    }

    &_item a.active::before,
    &_item a:hover::before {
      background: $brand;
    }
  }
}

.mobile-burgermenu-popup.opened {
  transition: all $trans;
  opacity: 1;
  max-width: 500px;
  visibility: visible;

  &::after {
    width: 100%;
  }
}

@include  m-max($xs) {
  .mobile-burgermenu-popup {
    left:0;
  }
}

@include m-min($sm) {
  .mobile-hamburger-menu {
    position: relative;
  }

  .mobile-burgermenu-popup {
    top: 90px;
  }
}

@include m-max($xs) {
  .mobile-burgermenu-popup {
    top: 80px;
    max-height: calc(100% - 80px);
  }
}

@include m-max(330) {
  .mobile-burgermenu-popup {
    top: 80px;
    max-height: calc(100% - 80px);
  }
}

@include m-min($lg) {
  .mobile-hamburger-menu {
    display: none;
  }

  .mobile-burgermenu-popup {
    padding: 25px;
  }
}