// Font-family.
$font: 'Quicksand', sans-serif;
$fontMontserrat: 'Montserrat', sans-serif;
$fontAwesome: 'FontAwesome';

:root {
  --brandColor: #E1625E;
  --orderLinkColor: #777;
  --opacityBrand: #9e4441a0;
}
// Color.
$white: #ffffff;
$black: #000000;
$brand: var(--brandColor);
$orderLinkColor: var(--orderLinkColor);
$opacityBrand: var(--opacityBrand);
$brand-hover: #9e4441;
$gray50: #f8f8f8;
$gray75: #e6e6e6;
$gray100: #cecdce;
$gray200: #dadada;
$gray400: #818181;
$yellow: #fc0;
$lightGreen: #dbffbe;
$error: #ff1414;

// Font-size.
$defsz: 16px;
$fszSm: 14px;

// Font-weight.
$light: 300;
$normalW: 400;
$bold500: 500;
$bold600: 600;
$bold700: 700;

// Breakpoints.
$xs: 414;
$sm: 768;
$md: 992;
$mdPro: 1024;
$lg: 1200;
$xl: 1400;

// Container size.
$smC: 720px;
$mdC: 960px;
$lgC: 1170px;
$xlC: 1370px;

// Padding Space.
$p15: 15px;

// Margin Space.
$m15: 15px;

// Components.
$shadow100: #00000029;
$trans: .3s ease-in-out;
$bradius: 4px;