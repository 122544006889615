@import '../helpers/helpers.scss';

body,
html,
#root{
  height: 100%;
}

.App {
  padding-top: 90px;
}

.error-message, .confirmation-message {
  color: #833767;
  margin: 0 auto 40px auto;
  border: 1px solid;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.flex-def {
  @include flex-def;
}

.flex-row-rev {
  @include flex-row-rev;
}

.flex-col {
  @include flex-col;
}

.flex-col-rev {
  @include flex-col-rev;
}

.flex-center {
  @include flex-center;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.container {
  @include container;
}

.row {
  @include row;
}

.clearfix {
  @include clearfix;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ffffff7a;

  img {
    height: 120px;
    transition: .5s;
  }

  &.hide img {
    height: 0;
  }
}

.content {
  padding-top: 30px;
  padding-bottom: 30px;
  flex: 1;
}

@include m-min($sm) {
  .App {
    padding-top: 105px;
  }
}

@include m-min($lg) {
  .content {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
