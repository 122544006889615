@import "../helpers/helpers.scss";

.basket-button {
  order: 1;
  display: flex;
  align-items: center;
}

.mobile-basket {
  * {
    font-family: $font;
  }

  &-btn {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      background: url(../../assets/shopping-cart.svg) no-repeat;
      position: absolute;
      height: 30px;
      width: 30px;
      opacity: 0;
      top: 4px;
      right: 0;
      background-size: cover;
    }

    &::before,
    img {
      transition: opacity $trans;
    }

    &.opened,
    &:hover {
      &::before {
        opacity: 1;
      }

      img {
        opacity: 0;
      }
    }
  }

  &-icon {
    width: 30px;
    height: 30px;
  }

  &-price {
    color: $brand;
    margin-right: 7px;
  }

  &-total-items {
    background: $brand;
    border-radius: 50%;
    overflow: hidden;
    width: 17px;
    height: 17px;
    position: absolute;
    top: -3px;
    right: -10px;
    color: $white;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.desk {
      top: 10px;
    }
  }
}

@include m-min($sm) {
  .basket-button {
    position: relative;
  }
}

@include m-min($lg) {
  .basket-button {
    display: none;
  }
}