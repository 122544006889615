@import "../helpers/helpers";

.login {
  &-logo {
    margin: 0 auto 50px;
    img {
      max-width:50%;
      margin:auto;
    }
  }

  &-page-title {
    font-family: $font;
    margin-bottom: 30px;
    display: block;
    line-height: 1.3;
  }

  &-page {
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 6px $shadow100;
    justify-content: center;
    padding: 5px;
  }

  &-btn {
    max-width: 120px;
  }
}
.trade-account {
  font-size:14px;
  color:$brand;
  padding-top:30px;
  display:block;
  text-align: center;
}


