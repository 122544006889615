@import "../helpers/helpers.scss";

.product-search {
  &-page {
    flex-direction: column;
  }

  &-filters {
    @include flex-def;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 30px;
  }

  &-tool {
    margin-bottom: 15px;
    width: 100%;

    p {
      margin-bottom: 8px;
      font-family: $font;
      font-weight: $bold500;
      font-size: $fszSm;
    }
  }

  &-button {
    width: 100%;
    color: $white;
  }
}

@include m-min($sm) {
  .product-search {
    &-tool {
      width: calc(50% - 8px);

      &:nth-child(odd) {
        margin-right: 15px;
      }
    }
  }
}

@include m-min($lg) {
  .product-search {
    &-filters {
      justify-content: space-between;
    }

    &-tool {
      width: calc(33% - 8px);

      &,
      &:nth-child(odd) {
        margin-right: 0;
      }
    }
  }
}