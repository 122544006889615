@import "../helpers/helpers.scss";

.product-details {
  .product-list {
    &,
    &-wrapper {
      transition: all $trans;
    }

    &-item {
      padding: 20px 0;
      border-bottom: 1px solid $gray400;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    &-item_wrapper {
      width: 100%;
    }
    &-item_inner {
      font-size: 0.875em;
    }
  }

  .product-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .product-img {
    width: 20%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-right: 160px;

    img {
      min-width: 100px;
      max-height: 310px;
      max-width: 310px;
      margin-bottom: 50px;
    }

    .download-icon {
      height: 70px;
      width: 70px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
      margin-bottom: 40px;
      background-color: #E1625E;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color:#fff;
      }
    }

    a {
      color: #E1625E;
      text-decoration: underline;
      font-size: 1.125em;
      font-family: "Quicksand", sans-serif;
    }
  }

  .update-cart-btn i::before, .update-cart-btn p {
    margin-bottom: 0;
  }

  .product-info {
    width: 60%;

    h2 {
      font-size: 1.875em;
      color: #818181;
      font-weight: 500;
    }

    p {
      font-size: 1.125em;
      font-family: "Quicksand", sans-serif;
      margin-bottom: 0;
    }

    .product-price {
      font-size: 1.375em;
      font-weight: 600;
      margin-bottom: 25px;
    }

  }

  .product-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }

  .product-description {
    margin-bottom: 50px;

    p {
      font-size: 1.125em;
      color: #818181;
      margin-bottom: 25px;
    }
  }

  .product-tags {
    display: flex;
    flex-wrap: wrap;
  }

  .product-tag {
    margin-right: 40px;
    margin-bottom: 20px;
    color: #E1625E;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
  }

  .product-info-circles {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .info-circle {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    width: 50%;

    p {
      font-size: 0.875em;
      padding-bottom:10px;
    }

    svg, label {
      color: #fff;
    }
  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-size: 56px 56px;
    background-position: center;
    background-color: #E1625E;
    margin-bottom: 20px;
    &.abv {
      background: url("../../assets/abv.svg") no-repeat;
      background-color: #E1625E;
    }
    &.ab-cert {
      background: url("../../assets/ab_cert.svg") no-repeat;
      background-color: #E1625E;
    }
    &.bio {
      background: url("../../assets/biodinamic.svg") no-repeat;
      background-color: #E1625E;
    }
    &.organic {
      background: url("../../assets/organic.svg") no-repeat;
      background-color: #E1625E;
    }
    &.origin {
      background: url("../../assets/origin.svg") no-repeat;
      background-color: #E1625E;
    }
    &.screw-cap {
      background: url("../../assets/screw_cap.svg") no-repeat;
      background-color: #E1625E;
    }
    &.terra-vitis {
      background: url("../../assets/terra_vitis.svg") no-repeat;
      background-color: #E1625E;
    }
    &.vegan {
      background: url("../../assets/vegan.svg") no-repeat;
      background-color: #E1625E;
    }
    &.vintage {
      background: url("../../assets/vintage.svg") no-repeat;
      background-color: #E1625E;
    }
    &.winemaker {
      background: url("../../assets/winemaker.svg") no-repeat;
      background-color: #E1625E;
    }

    svg, label {
      color: #fff;
    }
  }

  .fa-solid {
    color: white;
  }

  .fa-3x {
    font-size: 2.5em;
  }

  .product-list-item_inner.update-cart .disabled,
  .product-list-item_inner.update-cart .disabled:hover {
    padding-bottom: 8px;
  }

  @include m-max($sm) {

    .product-info {
      width: 100%;
    }

    .download-icon-cont {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .product-img {
      width: 100%;
      margin-right: 0;
      flex-direction: row;
      align-items: normal;
      justify-content: flex-start;

      a {
        font-size: 1em;
      }

      img {
        margin-right: 50px;
      }
    }

    .product-list-item_inner.input {
      align-items: normal;
    }

    .product-img .download-icon {
      margin-bottom: 10px;
    }

    .product-flex {
      flex-direction: column;
    }

    .product-stats {
      flex-direction: row;
      flex-wrap: wrap;
      .product-list-item_inner.input.stock {
        width:100%;
      }
      .product-list-item_inner.input.quantity,
      .product-list-item_inner.input.cart {
        flex: 1;
      }
      .product-list-item_inner.input.quantity {
        margin-bottom: 0;
      }

      .product-list-item_inner.update-cart {
        height: 38px;
      }
    }

    .product-list-item_inner.input + .product-list-item_inner.input {
      margin-left: 0;
    }

    .product-list-item_inner.update-cart p {
      margin-bottom: 0;

    }

    .product-list-item_inner.input .product-list-item-input-label {
      font-size: 1.125em;
    }

    .product-details .product-flex, .product-details .product-stats {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .product-list-item_inner.update-cart {
      margin-top: 0;
    }

    .info-circle {
      width: 25%;
    }

    .update-cart-btn {
      margin-bottom: 0;
      padding-bottom: 8px;
    }

  }

  @include m-min($sm) {

    .product-info {
      width: 100%;
    }

    .download-icon-cont {
      display: flex;
      flex-direction: column;
      align-items: center;

      .download-icon {
        margin-bottom: 10px;
      }
    }

    .product-img {
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 50px;

      img {
        margin-right: 140px;
        margin-bottom: 0;
      }
    }

    .product-flex {
      flex-direction: column;
    }

    .product-list-item_inner.input {
      align-items: normal;
    }

    .product-list-item-input-label {
      margin-bottom: 0;
    }

    .update-cart-btn.disabled.update-cart-product-list-table p {
      margin-bottom: 0;
    }

    .info-circle {
      padding-right: 0;
      padding-left: 0;
      margin-right: 0;
      width: 16%;

      p {
        font-size: 0.875em;
      }
    }

    .product-list-item_inner.input .product-list-item-input-label {
      font-size: 1.125em;
    }

    .product-list-item__qty-row,
    .update-cart-btn.disabled.update-cart-product-list-table {
      height: 38px;
    }

  }

  @include m-min($md) {

    .product-list-item {
      @include flex-def;
    }

    .product-flex {
      flex-direction: row;
    }

    .product-img {
      width: 20%;
      flex-direction: column;

      img {
        margin-right: 0;
        margin-bottom: 50px;
      }
    }

    .product-info {
      width: 60%;
    }

    .product-list-item_inner.input {
      max-width: 200px;
    }

    .download-icon-cont a {
      text-align: center;
    }

  }

  @include m-min($lg) {

    .product-list-item_inner.input {
      max-width: 140px;
    }

    .product-list-item-input-label {
      margin-bottom: 0;
      font-size: 1.125em;
    }

    .update-cart-btn.disabled {
      p {
        margin-bottom: 0;
      }
    }

    .product-list-item_inner.input + .product-list-item_inner.input {
      margin-left: 0;
    }

    .product-list-item_inner.input {
      align-items: normal;
      max-width: 190px;
    }
  }
}
@include m-max($md) {
  .update-cart-btn {
    margin-top: 0;
  }
}