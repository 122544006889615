@import "../helpers/helpers.scss";

.product-list {
  &,
  &-wrapper {
    transition: all $trans;
  }

  &-item {
    padding: 20px 0;
    border-bottom: 1px solid $gray400;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  &-item_wrapper {
    width: 100%;
  }
}

@include m-min($md) {
  .product-list-item {
    @include flex-def;
  }
}