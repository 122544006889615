@import "../helpers/helpers.scss";
.print_version {
  display: none;
}
.product_popup-content {
  background: $opacityBrand;

  .text,u {
    color: #fff;
  }
}
.product-details-title {
  padding-bottom: 20px;
  border-bottom: 1px solid $gray400;
}
.product-list-item {
  width: 100%;
  &_right__bottom__left,
  &_right__top,
  &_inner,
  &_right__bottom,
  &_wrapper {
    @include flex-def;
  }

  &_left {
    width: 0;

    img {
      display:none;

    }
  }

  &_right {
    width: 100%;
  }

  &_right__top {
    margin-bottom: 15px;
  }

  &_right__top &_inner {
    margin-bottom: 0;
    position: relative;
    padding-right: 20px;
    width: auto;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      border-right: 0;
      padding-right: 0;
    }
  }

  &_right__bottom {
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 45px;
  }
  &_right__bottom__right {
    padding-left:15px;
    @include flex-col;
  }

  &_right__bottom &_inner {
    width: auto;
  }

  &-title {
    text-align: left;
    transition: color $trans;
    margin-bottom: 0;
  }

  &-title-link {
    position: relative;
    display: inline-block;
    margin-bottom: 12px;

    &::before {
      position: absolute;
      bottom: -3px;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: $gray400;
      transition: all $trans;
    }

    &::after {
      position: absolute;
      bottom: -3px;
      left: 0;
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: $gray400;
      transition: all $trans;
    }

    &:hover {
      text-decoration: none;

      &::after {
        width: 100%;
      }
    }
  }

  &-label {
    margin-right: 5px;

    &.cases,
    &.bottles {
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  &-input-label {
    font-size: 14px;
    margin-bottom: 10px;
  }

  &_inner {
    margin-bottom: 15px;
    width: 100%;

    * {
      font-family: $font;
    }

    h6 {
      font-size: $fszSm;
    }

    p {
      font-size: $defsz;
    }
    svg {
      padding-right:5px;
    }
  }

  &_inner.case-nett, &_inner.bottle-nett {
    align-items: flex-end;
    width: 100%;
    margin-bottom: 15px;
    justify-content: flex-end;

    h6 {
      margin-bottom: 0;
      align-items: center;
    }

    p {
      line-height: 1;
      display: inline-flex;
      align-items: center;
      margin-bottom: -1px;
      font-size: 26px;
      font-weight: $bold500;
    }
  }

  &_inner.input {
    @include flex-col;
    align-items: center;
    margin-bottom: 0;
    max-width: 165px;

    & + & {
      margin-left: 15px;
    }
  }

  &_inner.input &-input-label {
    font-weight: $bold600;
  }

  //&_inner.input:last-of-type {
  //  margin-bottom: 0;
  //}

  &_inner.update-cart {
    margin-bottom: 0;
    width: 190px;
  }

  &__qty-row {
    display: flex;
    align-items: center;
  }
  &_inner.update-cart {
    width: 220px;
    .disabled {
      background-image: linear-gradient(
                      90deg, #999 0%, #888 35%, #777 100%);
      color: #ddd;
      cursor: pointer;
      &:hover {
        background-image: linear-gradient(
                        90deg, #999 0%, #888 35%, #777 100%);
        color: #ddd;
      }
    }
  }
}

@include m-max($sm) {
  .product-list-item {
    &_right__top &-item_inner {
      padding-top: 10px;
      padding-bottom: 10px;

      h6 {
        margin-bottom: 0;
      }
    }

    &_right__bottom {
      margin-top: 25px;
    }

    &_right__bottom__right,
    &_right__bottom__left {
      width: 50%;
    }

    &_right__bottom__right {
      @include flex-col-rev;
    }

    &-title {
      margin-bottom: 5px;
    }

    &_inner.input {
      width: calc(50% - 8px);
      max-width: 100%;
      @include m-max($sm) {
        width:100%;
        margin-bottom:3px;
        input {
          &:first-of-type, &:last-of-type {
            width: 50px;
          }
        }
      }
    }


    &_inner.case-nett, &_inner.bottle-nett {
      flex: auto;
      order: 3;
      margin-top: 15px;
      align-items: flex-end;
      margin-bottom: 0;

      h6 {
        margin-bottom: 0;
      }
    }

    &_inner.update-cart {
      margin-top: 15px;
      width: 100%;
    }
  }
}

@include m-min($sm) {
  .product-list-item {
    &_wrapper {
      align-items: center;
    }

    &_left {
      width: 20%;

      img {
        display: block;
        margin: 0 auto;
        min-width: 50px;
        max-height: 200px;
      }

    }
    &_right {
      width: 80%;
    }

    &_right__top {
      margin-bottom: 10px;
    }

    &_right__top &_inner {
      margin-bottom: 0;
      align-items: center;
      position: relative;
    }

    &-title-link {
      margin-bottom: 20px;
    }

    &_inner {
      margin-bottom: 15px;
      width: 100%;

      h6 {
        font-size: 16px;
      }

      p {
        font-size: 18px;
      }

      &.input input {
        width: 40%;
        padding: 5px;
        text-align: center;

        &[type=number]{
          width: 100%;
          &::-webkit-outer-spin-button,&::-webkit-inner-spin-button {
            -webkit-appearance: none;
            -moz-appearance: textfield;
          }
        }
      }
    }

    &_inner.case-nett, &_inner.bottle-nett {
      flex: 1;
    }
  }
}

@include m-min($md) {
  .product-list-item {
    width: 100%;

    &:last-of-type {
      border-bottom: 0;
    }

    &_inner.case-nett, &_inner.bottle-nett {
      flex: auto;
      flex-direction: row;
      align-items: flex-end;

      h6 {
        margin-bottom: 0;
      }
    }
  }
}

// TODO: will be removed after approve.
////DESK version 1
//@include m-min($lg) {
//  .product-list-item {
//    &_wrapper {
//      justify-content: center;
//    }
//
//    &_right {
//      width: auto;
//    }
//  }
//}

//DESK version 2
@include m-min($lg) {
  .product-list-item {
    &_right {
      @include flex-def;
    }

    &-title-link-wrapper {
      width: 100%;
    }

    &-title-link {
      margin-bottom: 30px;
    }

    &_right__top,
    &_right__bottom {
      align-items: center;
      align-content: center;
    }

    &_right__top {
      margin-bottom: 0;
    }

    &_right__bottom {
      margin-top: 0;
      padding-left: 30px;
      margin-left: auto;
    }

    &_inner.input {
      max-width: 120px;
    }

    &_left img {
      max-height: 150px;
    }
  }
}

