@import "../helpers/helpers.scss";

.required-qty-msg {
  margin: 20px 0;
}
.close_modal {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.popup-content {
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
  //text-align: center;
  //border-radius: 10px;
  //line-height: 21px;
  //padding: 20px 10px;

  .header-logo-img {
    margin-bottom: 40px;
  }
}
.page.checkout-page {
  flex-direction: column;

  h2 {
    width: 100%;
    padding-bottom: 20px;
  }

  input {
    -webkit-appearance: radio;
  }
}

.checkout {
  &__form {
    display: flex;
    justify-content: space-around;
    @include m-max($sm) {
      flex-direction: column;
    }

    &,
    .page-title {
      width: 100%;
    }
  }

  &__submit_wrapper {
    flex-direction: column;
    width: 100%;
  }

  &__delivery_title,
  &__details_title {
    text-align: left;
    margin-bottom: 15px;
    font-weight: bold;
  }

  &__first_step,
  &__second_step {
    width: 45%;
    @include m-max($sm) {
      width: 100%;
    }
  }

  &__delivery_item {
    display: flex;
    padding: 20px 0;
    border-top: 1px solid $gray400;

    &:nth-of-type(1) {
      border-top: 0;
    }
  }

  &__delivery-notes-title {
    margin-bottom: 5px;
  }

  &__delivery_field {
    font-weight: 300;
    line-height: 25px;
  }

  &__details_field {
    margin-top: 20px;
  }

  &__delivery_item input[type="radio"] {
    width: 20px;
    height: 20px;
  }

  &__delivery_info {
    padding-left: 10px;
  }

  &__second_step &__details_field {
    margin: 10px 0;
  }

  &__details_textarea {
    width: 100%;
    height: 200px;
    border: none;
    background: $gray75;
    padding: 10px;
    border-radius: 4px;
    resize: none;

    &:active,
    &:focus {
      outline: none;
      border: 1px solid $brand;
    }
  }

  &__submit_wrapper {
    margin-top: 20px;

    .grey {
      background: #ccc;
    }
    button {
      width: 10em;
      margin: 0 10px;
    }
  }
}

