@import "../helpers/helpers.scss";

.update-cart {
  position: relative;

  &-btn {
    @include flex-center;
    z-index: 6;
    cursor: pointer;
    display: inline-flex;
    margin-bottom: 0;
    margin-top: 1px;
    padding: 6px 0 7px;
    box-shadow: 0 3px 6px $shadow100;

    i {
      margin-left: 10px;
      font-family: $fontAwesome;
    }

    i::before {
      font-size: 22px;
    }

    p,
    i::before {
      color: $white;
    }
  }

  &-btn.update-cart-product-list-table.refreshed {
    box-shadow: 0 0 5px $black;

    & p,
    i:before {
      color: $lightGreen;
    }
  }
}

@include m-max($sm) {
  .update-cart-product-list-table {
    margin-bottom: 4px;
  }
}

.add-to-cart {
  opacity: 0;
  width: 100%;
  box-sizing: border-box;
  background: $brand;
  color: $white;
  position: absolute;
  top: 0px;
  transition: 0.5s;
  padding: 6px 8px;
  z-index: 5;
  border-radius: 5px;
  height: 35px;
  font-size: 11px;
}

.added {
  transition: 0.5s;
  top: 40px;
  opacity: 1;
}